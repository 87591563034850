.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.dialog-title-text-ellipsis h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 1055px) {
    .about-us-person-box {
        width: 135px !important;
    }
}

@media screen and (max-width: 800px) {
    .book-list-table-view {
        display: none !important;
    }
    .book-list-card-view {
        display: block !important;
    }
}

@media screen and (max-width: 900px) {
    .book-info-dialog-header {
        width: 500px;
    }
    .book-info-dialog-content {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 570px) {
    .book-info-dialog .MuiPaper-root {
        margin: unset !important;
        width: 100%;
        max-height: 100%;
        max-width: unset;
        border-radius: 0;
    }
} 

@media screen and (min-width: 660px) {
    .custom-grid-item {
        padding-left: 10px;
        padding-right: 10px;
    }
}

